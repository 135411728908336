import { computed, ref } from "vue";
/* eslint-disable */ 
export function usePagination(dataList, pageSize = 10, maxVisiblePages = 2) {
    const currentPage = ref(1);

    const totalPages = computed(() => Math.ceil(dataList.value.length / pageSize));

    const paginatedData = computed(() => {
        const start = (currentPage.value - 1) * pageSize;
        const end = start + pageSize;
        return dataList.value.slice(start, end);
    });

    const changePage = (offset) => {
        const newPage = currentPage.value + offset;
        if (newPage >= 1 && newPage <= totalPages.value) {
            currentPage.value = newPage;
        }
    };

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages.value) {
            currentPage.value = page;
        }
    };

    const visiblePages = computed(() => {
        const startPage = Math.max(1, currentPage.value - maxVisiblePages);
        const endPage = Math.min(totalPages.value, currentPage.value + maxVisiblePages);
        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    });

    return {
        pageSize,
        currentPage,
        totalPages,
        paginatedData,
        changePage,
        goToPage,
        visiblePages,
    };
}
