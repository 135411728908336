import axios from "axios";
import config from './config';


class HttpClient {

//partie organisme
    searchOrganismes($filter, $type){
        return axios.get(`${config.API_URL}organismes/searchOrganisme/?filter=${$filter}&type=${$type}`).then(response => response.data);
    }

    getOrganismesByCriteria($criteria){
        return axios.get(`${config.API_URL}organismes/getOrganismesByCriteria/?criteria=${$criteria}`).then(response => response.data);
    }

    // partie application
    getApplicationsByCriteria($criteria){
        return axios.get(`${config.API_URL}application/getApplicationsByCriteria/?criteria=${$criteria}`).then(response => response.data);
    }

    searchApplications($keyword, $idThem, $resolution){
        return axios.get(`${config.API_URL}application/searchApplications/?keyword=${$keyword}&idThem=${$idThem}&resolution=${$resolution}`).then(response => response.data);
    }
}

export default new HttpClient()