import {ref} from "vue";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function listSelect(){
    const listThematiques = ref([
        { value: "1", label: "Sol : Erosion et ruissellement" },
        { value: "2", label: "Sol : Dynamique structurale (tassement, battance…)" },
        { value: "3", label: "Sol : Artificialisation" },
        { value: "4", label: "Sol : Acidification" },
        { value: "5", label: "Sol : Salinisation" },
        { value: "6", label: "Sol : Fertilité (N, P, K)" },
        { value: "7", label: "Sol : Fond géochimique et contamination (ETM, polluants…)" },
        { value: "8", label: "Sol : Biodiversité (vie du sol)" },
        { value: "9", label: "Sol : Carbone et matière organique" },
        { value: "10", label: "Sol : Autres" },
        { value: "11", label: "Eau qualitatif : Phosphore" },
        { value: "12", label: "Eau qualitatif : Nitrates" },
        { value: "13", label: "Eau qualitatif : Pesticides et/ou phytosanitaires" },
        { value: "14", label: "Eau qualitatif : Autres" },
        { value: "15", label: "Eau quantitatif : Diagnostic et estimation des ressources" },
        { value: "16", label: "Eau quantitatif : Programmes de gestion de l'eau et pratiques d'irrigation" },
        { value: "17", label: "Eau quantitatif : Autres" },
        { value: "18", label: "GES : N2O" },
        { value: "19", label: "GES : CO2 et stockage" },
        { value: "20", label: "Protection de la faune, de la flore et de la biodiversité" },
        { value: "21", label: "Trame verte et bleue" },
        { value: "22", label: "Zones humides" },
        { value: "23", label: "Caractérisation des paysages et habitats" },
        { value: "24", label: "Conseils et références agricole / sylvicole" },
        { value: "25", label: "Gestion et suivi de la production agricole / sylvicole" },
        { value: "26", label: "Aptitude agricole / sylvicole" },
        { value: "27", label: "Aptitude à l'assainissement (drainage entre autre)" },
        { value: "28", label: "Aptitude à l'épandage (effluents, composts, boues)" },
        { value: "29", label: "Zones agricoles défavorisées" },
        { value: "30", label: "Stations forestières" },
        { value: "31", label: "Production : agro-industries (biocarburants, etc.)" },
        { value: "32", label: "Production : autres industries (productrice de déchets / polluants)" },
        { value: "33", label: "Travaux d'aménagement et étude d'impact" },
        { value: "34", label: "Aptitude à la construction" },
        { value: "35", label: "Assainissements" },
        { value: "36", label: "Grand ouvrages linéaires / réseaux" },
        { value: "37", label: "Documents d'urbanisme et d'aménagement du territoire (SCOT, etc.)" },
        { value: "38", label: "Aléas et risques naturels" },
        { value: "39", label: "Gestion et prévention des risques de santé publique" },
        { value: "40", label: "Archéologie / Histoire" },
        { value: "41", label: "Typologie des sols" },
        { value: "42", label: "Réalisation de découpages divers / à différentes échelles" }
    ])

    const listEchelles = ref([
        { value: "1", label: "1/2000 - 1/10 000" },
        { value: "2", label: "1/10 000 - 1/50 000" },
        { value: "3", label: "1/50 000 - 1/100 000" },
        { value: "4", label: "1/100 000 - 1/200 000" },
        { value: "5", label: "1/200 000 - 1/250 000" },
        { value: "6", label: "1/250 000 - 1/1 000 000" }
    ])

    const listTypesOrg = ref([
        { value: "1", label: "Association" },
        { value: "2", label: "Bureau d'études" },
        { value: "3", label: "Collectivité territoriale" },
        { value: "4", label: "Organisme d'enseignement" },
        { value: "5", label: "Institut technique" },
        { value: "6", label: "Laboratoire d'analyses" },
        { value: "7", label: "Organisation professionnelle" },
        { value: "8", label: "Organisme de recherche" },
        { value: "9", label: "Organisme public" },
        { value: "99", label: "Organisme non classé" }
    ])

    return{
        listThematiques,
        listEchelles,
        listTypesOrg
    }
}